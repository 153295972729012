<template>
  <div id='user-view'>
    <v-card>
      <v-card-title class='px-2'>
        <Comeback2 />
        {{ $t('Incomreport2') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          :loading='exportLoading'
          class='d-none d-md-block'
          :disabled='exportLoading'
          @click='exportExcel'
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color='primary'
          class='d-block d-md-none'
          :loading='exportLoading'
          :disabled='exportLoading'
          icon
          fab
          outlined
          @click='exportExcel'
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate='addPayload' />
      <v-row class='px-2'>
        <v-col cols='12' md='6' class='py-0' lg='3' v-if='store.state.shopMainStatus'>
          <v-select
            v-model.trim='branchSelection'
            :items='branchList'
            outlined
            @change='addPayload(payload.start,payload.end)'
            dense
            :label="$t('select_branch')"
            item-text='shop_name'
            :no-data-text="$t('no_data')"
            item-value='shop_id_pri'
          ></v-select>
        </v-col>
      </v-row>

      <v-tabs v-model='currentTab' show-arrows class='user-tabs'>
        <v-tab v-for='tab in tabs' :key='tab.icon'>
          <span>{{ $t(tab.title) }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items id='user-tabs-content' v-model='currentTab'>
        <v-tab-item transition='none'>
          <v-data-table
            :headers='columns'
            :items='dataTableList'
            :options.sync='options'
            :loading='loading'
            disable-sort
            hide-default-header
            hide-default-footer
            item-key='number'
            :loading-text="$t('data_loading')"
            :no-data-text="$t('no_information')"
          >
            <template v-slot:header>
              <tr height='43px'>
                <th rowspan='2' class='text-left px-4' style='min-width: 150px'>#</th>
                <th rowspan='2' class='text-left px-4' style='min-width: 200px'>{{ $t('branch') }}
                </th>
                <th rowspan='2' class='text-left px-4' style='min-width: 200px'>{{ $t('date') }}
                </th>
                <th rowspan='2' class='text-left px-4' style='min-width: 200px'>
                  {{ $t('firstname_lastname') }}
                </th>
                <th rowspan='2' class='text-left px-4' style='min-width: 150px'>
                  {{ $t('receipt_code') }}
                </th>
                <th rowspan='2' class='text-right px-4 vertical' style='min-width: 150px'>
                  {{ $t('saleTotal') }}
                </th>
                <th rowspan='2' class='text-right px-4 vertical' style='min-width: 150px'>
                  {{ $t('discount') }}
                </th>
                <th rowspan='2' class='text-right px-4 vertical' style='min-width: 150px'>
                  {{ $t('point') }}
                </th>
                <th rowspan='2' class='text-right px-4 vertical' style='min-width: 150px'>
                  {{ $t('total_income') }}
                </th>
                <th rowspan='2' class='text-right px-4 vertical' style='min-width: 150px'>
                  {{ $t('owe') }}
                </th>
                <th colspan='5' class='text-center px-4 vertical'>{{ $t('salePayment') }}</th>
                <th colspan='5' class='text-center px-4 vertical'>{{ $t('invoicePayment') }}</th>
                <!--            <th rowspan="2" class="text-right px-4 vertical" style="min-width: 150px">{{ $t('reduce debt') }}</th>-->
                <th rowspan='2' class='text-right px-4 vertical' style='min-width: 150px'>
                  {{ $t('total_income') }}
                </th>
              </tr>
              <tr height='43px'
                  style='border-bottom: thin solid rgba(50, 50, 50, 0.14) !important!'>
                <th class='text-right px-4 vertical' style='min-width: 200px'>{{ $t('payment_cash')
                  }}
                </th>
                <th class='text-right px-4 vertical' style='min-width: 200px'>
                  {{ $t('payment_via_bank') }}
                </th>
                <th class='text-right px-4 vertical' style='min-width: 200px'>
                  {{ $t('Credit_Card_Payment') }}
                </th>
                <th class='text-right px-4 vertical' style='min-width: 200px'>
                  {{ $t('online_payment') }}
                </th>
                <th class='text-right px-4 vertical' style='min-width: 200px'>{{ $t('other_payment')
                  }}
                </th>
                <th class='text-right px-4 vertical' style='min-width: 200px'>{{ $t('payment_cash')
                  }}
                </th>
                <th class='text-right px-4 vertical' style='min-width: 200px'>
                  {{ $t('payment_via_bank') }}
                </th>
                <th class='text-right px-4 vertical' style='min-width: 200px'>
                  {{ $t('Credit_Card_Payment') }}
                </th>
                <th class='text-right px-4 vertical' style='min-width: 200px'>
                  {{ $t('online_payment') }}
                </th>
                <th class='text-right px-4 vertical' style='min-width: 200px'>{{ $t('other_payment')
                  }}
                </th>
              </tr>
            </template>
            <template v-slot:[`item.order_create`]='{ item }'>
              {{ item.order_create | sumdatetime }}
            </template>
            <template v-slot:[`item.order_id`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.order_id }}
              </span>
            </template>
            <template v-slot:[`item.sum_before_total`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.sum_before_total | formatPrice }}
              </span>
            </template>
            <template v-slot:[`item.sum_discount`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.sum_discount }}
              </span>
            </template>
            <template v-slot:[`item.order_pointsave`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ +item.order_pointsave | formatPrice }}
              </span>
            </template>
            <template v-slot:[`item.sum_after_total`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.sum_after_total }}
              </span>
            </template>
            <template v-slot:[`item.orderpay_balance`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.orderpay_balance }}
              </span>
            </template>
            <template v-slot:[`item.pay_by_cash`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.pay_by_cash }}
              </span>
            </template>
            <template v-slot:[`item.pay_by_bank`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.pay_by_bank }}
              </span>
            </template>
            <template v-slot:[`item.pay_by_credit`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.pay_by_credit }}
              </span>
            </template>
            <template v-slot:[`item.pay_by_application`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.pay_by_application }}
              </span>
            </template>
            <template v-slot:[`item.pay_by_other`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.pay_by_other }}
              </span>
            </template>
            <template v-slot:[`item.expenses_by_cash`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.expenses_by_cash }}
              </span>
            </template>
            <template v-slot:[`item.expenses_by_bank`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.expenses_by_bank }}
              </span>
            </template>
            <template v-slot:[`item.expenses_by_credit`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.expenses_by_credit }}
              </span>
            </template>
            <template v-slot:[`item.expenses_by_application`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.expenses_by_application }}
              </span>
            </template>
            <template v-slot:[`item.expenses_by_other`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.expenses_by_other }}
              </span>
            </template>
            <template v-slot:[`item.reduce_debt`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.reduce_debt }}
              </span>
            </template>
            <template v-slot:[`item.total_payment`]='{ item }'>
              <span :class="item.order_id == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.total_payment }}
              </span>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item transition='none'>
          <v-data-table
            :headers='columns2'
            :items='dataTableList2'
            :options.sync='options'
            :loading='loading'
            disable-sort
            hide-default-footer
            item-key='number'
            :loading-text="$t('data_loading')"
            :no-data-text="$t('no_information')"
          >
            <template v-slot:[`item.shop_bank_name`]='{ item }'>
              <span
                :class="item.shop_bank_name == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.shop_bank_name }}
              </span>
            </template>
            <template v-slot:[`item.orderpay_current`]='{ item }'>
              <span
                :class="item.shop_bank_name == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.orderpay_current }}
              </span>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item transition='none'>
          <v-data-table
            :headers='columns2'
            :items='dataTableList3'
            :options.sync='options'
            :loading='loading'
            disable-sort
            hide-default-footer
            item-key='number'
            :loading-text="$t('data_loading')"
            :no-data-text="$t('no_information')"
          >
            <template v-slot:[`item.shop_bank_name`]='{ item }'>
              <span
                :class="item.shop_bank_name == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.shop_bank_name }}
              </span>
            </template>
            <template v-slot:[`item.orderpay_current`]='{ item }'>
              <span
                :class="item.shop_bank_name == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.orderpay_current }}
              </span>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item transition='none'>
          <v-data-table
            :headers='columns2'
            :items='dataTableList4'
            :options.sync='options'
            :loading='loading'
            disable-sort
            hide-default-footer
            item-key='number'
            :loading-text="$t('data_loading')"
            :no-data-text="$t('no_information')"
          >
            <template v-slot:[`item.shop_bank_name`]='{ item }'>
              <span
                :class="item.shop_bank_name == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.shop_bank_name }}
              </span>
            </template>
            <template v-slot:[`item.orderpay_current`]='{ item }'>
              <span
                :class="item.shop_bank_name == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.orderpay_current }}
              </span>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item transition='none'>
          <v-data-table
            :headers='columns2'
            :items='dataTableList5'
            :options.sync='options'
            :loading='loading'
            disable-sort
            hide-default-footer
            item-key='number'
            :loading-text="$t('data_loading')"
            :no-data-text="$t('no_information')"
          >
            <template v-slot:[`item.shop_bank_name`]='{ item }'>
              <span
                :class="item.shop_bank_name == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.shop_bank_name }}
              </span>
            </template>
            <template v-slot:[`item.orderpay_current`]='{ item }'>
              <span
                :class="item.shop_bank_name == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.orderpay_current }}
              </span>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item transition='none'>
          <v-data-table
            :headers='columns2'
            :items='dataTableList6'
            :options.sync='options'
            :loading='loading'
            disable-sort
            hide-default-footer
            item-key='number'
            :loading-text="$t('data_loading')"
            :no-data-text="$t('no_information')"
          >
            <template v-slot:[`item.shop_bank_name`]='{ item }'>
              <span
                :class="item.shop_bank_name == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.shop_bank_name }}
              </span>
            </template>
            <template v-slot:[`item.orderpay_current`]='{ item }'>
              <span
                :class="item.shop_bank_name == $t('total') ? 'font-weight-bold primary--text' : ''">
                {{ item.orderpay_current }}
              </span>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import branch from '@/api/branch'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { reportIncome, removeComma } from '../useExcel2'
import { sumdate, sumdatetime, formatPrice, removeFormatPrice } from '@/plugins/filters'
import Comeback2 from '../Comeback2.vue'
import XLSX from 'xlsx'
import store from '@/store/app'

export default {
  components: {
    DateFilters,
    Comeback2,
  },
  filters: {
    removeFormatPrice,
    sumdatetime,
    formatPrice,
  },
  setup() {
    const XLSX = require('xlsx')
    const tabs = ref([
      { title: 'IncomeSum' },
      { title: 'payment_cash' },
      { title: 'payment_via_bank' },
      { title: 'Credit_Card_Payment' },
      { title: 'online_payment' },
      { title: 'other_payment' },
    ])
    const courseGroupSelect = ref('')
    const courseGroupList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: '#', value: 'shop_name', width: 50 },
      { text: '#', value: 'order_create', width: 50 },
      { text: '#', value: 'customer_fullName', width: 50 },
      { text: '#', value: 'order_id', width: 50 },
      { text: '#', value: 'sum_before_total', width: 50, align: 'end vertical' },
      { text: '#', value: 'sum_discount', width: 50, align: 'end vertical' },
      { text: '#', value: 'order_pointsave', width: 50, align: 'end vertical' },
      { text: '#', value: 'sum_after_total', width: 50, align: 'end vertical' },
      { text: '#', value: 'orderpay_balance', width: 50, align: 'end vertical' },
      { text: '#', value: 'pay_by_cash', width: 50, align: 'end vertical' },
      { text: '#', value: 'pay_by_bank', width: 50, align: 'end vertical' },
      { text: '#', value: 'pay_by_credit', width: 50, align: 'end vertical' },
      { text: '#', value: 'pay_by_application', width: 50, align: 'end vertical' },
      { text: '#', value: 'pay_by_other', width: 50, align: 'end vertical' },
      { text: '#', value: 'expenses_by_cash', width: 50, align: 'end vertical' },
      { text: '#', value: 'expenses_by_bank', width: 50, align: 'end vertical' },
      { text: '#', value: 'expenses_by_credit', width: 50, align: 'end vertical' },
      { text: '#', value: 'expenses_by_application', width: 50, align: 'end vertical' },
      { text: '#', value: 'expenses_by_other', width: 50, align: 'end vertical' },
      // { text: '#', value: 'reduce_debt', width: 50, align: 'end vertical' },
      { text: '#', value: 'total_payment', width: 50, align: 'end vertical' },
    ])
    const columns2 = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('list'), value: 'shop_bank_name' },
      { text: i18n.t('total_'), value: 'orderpay_current', align: 'end' },
    ])
    const dataTableList = ref([])
    const loading = ref(false)
    const options = ref({})
    const currentTab = ref(null)
    const header = ref('')
    const payload = ref({})
    const dataTableList2 = ref([])
    const dataTableList3 = ref([])
    const dataTableList4 = ref([])
    const dataTableList5 = ref([])
    const dataTableList6 = ref([])
    const exportLoading = ref(false)
    const branchList = ref([])
    const branchSelection = ref('')

    branch
      .branchListForSelection()
      .then(res => {
        branchSelection.value = res[0].shop_id_pri
        branchList.value = res
      })
      .catch(error => {
        console.log('error : ', error)
      })

    const addPayload = (start, end) => {
      console.log('start : ', start)
      console.log('end : ', end)
      payload.value = {
        start,
        end,
        main_shop_id_pri: branchSelection.value,
        lang: i18n.locale,
      }

      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      const { start, end } = payload
      const {
        data,
        cash_list,
        bank_list,
        other_list,
        application_list,
        credit_list,
      } = await reportIncome(payload)
      dataTableList.value = data
      dataTableList2.value = cash_list
      dataTableList3.value = bank_list
      dataTableList4.value = credit_list
      dataTableList5.value = application_list
      dataTableList6.value = other_list

      header.value = `${i18n.t('Incomreport2')} ${i18n.t('detail_at_day')} ${i18n.t('since')} ${sumdate(
        start,
      )} ${i18n.t('to')} ${sumdate(end)}`

      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const fileName = `${header.value}.xlsx`
      const Heading = [
        [header.value],
        [
          `#`,
          `${i18n.t('branch')}`,
          `${i18n.t('date')}`,
          `${i18n.t('firstname_lastname')}`,
          `${i18n.t('receipt_code')}`,
          `${i18n.t('saleTotal')}`,
          `${i18n.t('discount')}`,
          `${i18n.t('point')}`,
          `${i18n.t('total_income')}`,
          `${i18n.t('owe')}`,
          `${i18n.t('salePayment')}`,
          ``,
          ``,
          ``,
          ``,
          `${i18n.t('invoicePayment')}`,
          ``,
          ``,
          ``,
          ``,
          // `${i18n.t('reduce debt')}`,
          `${i18n.t('total_income')}`,
        ],
        [
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          `${i18n.t('payment_cash')}`,
          `${i18n.t('payment_via_bank')}`,
          `${i18n.t('Credit_Card_Payment')}`,
          `${i18n.t('online_payment')}`,
          `${i18n.t('other_payment')}`,
          `${i18n.t('payment_cash')}`,
          `${i18n.t('payment_via_bank')}`,
          `${i18n.t('Credit_Card_Payment')}`,
          `${i18n.t('online_payment')}`,
          `${i18n.t('other_payment')}`,
          // `${i18n.t('reduce debt')}`,
          `${i18n.t('total_income')}`,
        ],
      ]
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, await removeComma(JSON.parse(JSON.stringify(dataTableList.value))), {
        header: [
          'number',
          'shop_name',
          'order_create',
          'customer_fullName',
          'order_id',
          'sum_before_total',
          'sum_discount',
          'order_pointsave',
          'sum_after_total',
          'orderpay_balance',
          'pay_by_cash',
          'pay_by_bank',
          'pay_by_credit',
          'pay_by_application',
          'pay_by_other',
          'expenses_by_cash',
          'expenses_by_bank',
          'expenses_by_credit',
          'expenses_by_application',
          'expenses_by_other',
          // 'reduce_debt',
          'total_payment',
        ],
        skipHeader: true,
        origin: -1,
      })


      const wb = XLSX.utils.book_new()
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 20 } },
        { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },
        { s: { r: 1, c: 1 }, e: { r: 2, c: 1 } },
        { s: { r: 1, c: 2 }, e: { r: 2, c: 2 } },
        { s: { r: 1, c: 3 }, e: { r: 2, c: 3 } },
        { s: { r: 1, c: 4 }, e: { r: 2, c: 4 } },
        { s: { r: 1, c: 5 }, e: { r: 2, c: 5 } },
        { s: { r: 1, c: 6 }, e: { r: 2, c: 6 } },
        { s: { r: 1, c: 7 }, e: { r: 2, c: 7 } },
        { s: { r: 1, c: 8 }, e: { r: 2, c: 8 } },
        { s: { r: 1, c: 9 }, e: { r: 2, c: 9 } },
        { s: { r: 1, c: 10 }, e: { r: 1, c: 14 } },
        { s: { r: 1, c: 15 }, e: { r: 1, c: 19 } },
        { s: { r: 1, c: 20 }, e: { r: 2, c: 20 } },
        { s: { r: 1, c: 21 }, e: { r: 2, c: 21 } },
      ]
      const wscols = [
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols

      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('Incomreport2')}`)

      /* พิมร์ files */
      /*  =============================================================================================================================================================Chash */
      const Heading2 = [[`${i18n.t('payment_cash')} `], ['#', i18n.t('list'), i18n.t('total_')]]
      const ws2 = XLSX.utils.aoa_to_sheet(Heading2)
      XLSX.utils.sheet_add_json(ws2, await removeComma(JSON.parse(JSON.stringify(dataTableList2.value))), {
        header: ['number', 'shop_bank_name', 'orderpay_current'],
        skipHeader: true,
        origin: -1,
      })

      const merge2 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }]
      const wscols2 = [{ wch: 10 }, { wch: 20 }, { wch: 20 }]
      ws2['!merges'] = merge2
      ws2['!cols'] = wscols2

      XLSX.utils.book_append_sheet(wb, ws2, `${i18n.t('payment_cash')}`)

      /*  =============================================================================================================================================================payment_via_bank */
      const Heading3 = [[`${i18n.t('payment_via_bank')} `], ['#', i18n.t('list'), i18n.t('total_')]]
      const ws3 = XLSX.utils.aoa_to_sheet(Heading3)
      XLSX.utils.sheet_add_json(ws3, await removeComma(JSON.parse(JSON.stringify(dataTableList3.value))), {
        header: ['number', 'shop_bank_name', 'orderpay_current'],
        skipHeader: true,
        origin: -1,
      })

      const merge3 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }]
      const wscols3 = [{ wch: 10 }, { wch: 20 }, { wch: 20 }]
      ws2['!merges'] = merge3
      ws2['!cols'] = wscols3

      XLSX.utils.book_append_sheet(wb, ws3, `${i18n.t('payment_via_bank')}`)

      /*  =============================================================================================================================================================Credit_Card_Payment */
      const Heading4 = [[`${i18n.t('Credit_Card_Payment')} `], ['#', i18n.t('list'), i18n.t('total_')]]
      const ws4 = XLSX.utils.aoa_to_sheet(Heading4)
      XLSX.utils.sheet_add_json(ws4, await removeComma(JSON.parse(JSON.stringify(dataTableList4.value))), {
        header: ['number', 'shop_bank_name', 'orderpay_current'],
        skipHeader: true,
        origin: -1,
      })

      const merge4 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }]
      const wscols4 = [{ wch: 10 }, { wch: 20 }, { wch: 20 }]
      ws2['!merges'] = merge4
      ws2['!cols'] = wscols4

      XLSX.utils.book_append_sheet(wb, ws4, `${i18n.t('Credit_Card_Payment')}`)

      /*  =============================================================================================================================================================Credit_Card_Payment */
      const Heading5 = [[`${i18n.t('online_payment')} `], ['#', i18n.t('list'), i18n.t('total_')]]
      const ws5 = XLSX.utils.aoa_to_sheet(Heading5)
      XLSX.utils.sheet_add_json(ws5, await removeComma(JSON.parse(JSON.stringify(dataTableList5.value))), {
        header: ['number', 'shop_bank_name', 'orderpay_current'],
        skipHeader: true,
        origin: -1,
      })

      const merge5 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }]
      const wscols5 = [{ wch: 10 }, { wch: 20 }, { wch: 20 }]
      ws2['!merges'] = merge5
      ws2['!cols'] = wscols5

      XLSX.utils.book_append_sheet(wb, ws5, `${i18n.t('online_payment')}`)

      /*  =============================================================================================================================================================Credit_Card_Payment */
      const Heading6 = [[`${i18n.t('other_payment')} `], ['#', i18n.t('list'), i18n.t('total_')]]
      const ws6 = XLSX.utils.aoa_to_sheet(Heading6)
      XLSX.utils.sheet_add_json(ws6, await removeComma(JSON.parse(JSON.stringify(dataTableList6.value))), {
        header: ['number', 'shop_bank_name', 'orderpay_current'],
        skipHeader: true,
        origin: -1,
      })

      const merge6 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }]
      const wscols6 = [{ wch: 10 }, { wch: 20 }, { wch: 20 }]
      ws2['!merges'] = merge6
      ws2['!cols'] = wscols6

      XLSX.utils.book_append_sheet(wb, ws6, `${i18n.t('other_payment')}`)

      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    // watch([branchSelection], () => {
    //   addPayload(payload.value.start, payload.value.end)
    // })

    return {
      exportLoading,
      courseGroupSelect,
      columns,
      dataTableList,
      loading,
      options,
      payload,
      tabs,
      courseGroupList,
      columns2,
      currentTab,
      mdiFileExcelOutline,
      branchSelection,
      branchList,
      dataTableList2,
      dataTableList3,
      dataTableList4,
      removeFormatPrice,
      dataTableList5,
      dataTableList6,
      store,
      addPayload,
      exportExcel,
    }
  },
}
</script>
<style lang='scss'>
.vertical {
  border-left: 1px solid rgba(50, 50, 50, 0.14);
  height: 43px;
}

.theme--light.v-data-table th {
  border-top: thin solid rgba(50, 50, 50, 0.14);
  color: rgba(50, 50, 50, 0.87) !important;
  border-bottom: thin solid rgba(50, 50, 50, 0.14);
}

@import '@core/preset/preset/apps/user.scss';
</style>
